<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>