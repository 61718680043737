<template>
  <div class="content">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.content {
  padding: 2px 12px 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>