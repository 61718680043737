<template>
  <layout-wrapper v-if="isTab">
    <header class="header-title">
      <span>塔尖充值</span>
    </header>
    <layout-content>
      <div class="main-content">
        <input
          type="text"
          v-model="userId"
          placeholder="请输入你要充值的ID或手机号"
          class="input-pay"
          @keydown.enter="onSearchUser"
          @input="onSearchUser"
        />
        <span class="tips"
          >请确认好您的充值ID或手机号，如有问题请联系客服！</span
        >
        <div class="userinfo" v-if="isUser">
          <img :src="userinfo.avatar" alt="" />
          <div class="userinfo-info">
            <span class="title">{{ userinfo.user_nicename }}</span>
            <p>
              塔尖ID:<span>{{ userinfo.id }}</span>
            </p>
          </div>
        </div>
        <div class="money-list">
          <ul>
            <li
              v-for="(item, index) in payList.data"
              :class="index === active ? 'active' : ''"
              :key="index"
              @click="onSelectMoney(index)"
            >
              <div class="top">
                <img src="../assets/images/diamond.png" alt="" />
                <span class="coin">{{ item.coin }}</span>
              </div>
              <span class="money">{{ item.money }}元</span>
            </li>
            <li>
              <input
                type="text"
                class="custom"
                placeholder="自定义金额"
                v-model.number="customMoney"
                :style="
                  isFocus
                    ? {
                        border: '1px solid #d45e7e',
                        backgroundColor: 'rgba(252, 233, 237, 0.7)',
                      }
                    : {}
                "
                @focus="onInputFocus"
                @blur="isFocus = false"
              />
            </li>
            <!-- <li style="opacity: 0"></li> -->
          </ul>
        </div>
        <util-screen v-if="isIOS" />
      </div>
      <div class="pay" @click="onOwnPay">立即充值</div>
      <div class="kefu">
        <a :href="kefu_url">联系客服</a>
      </div>
      <VanNotify></VanNotify>
    </layout-content>
  </layout-wrapper>
  <layout-wrapper v-else>
    <header class="header-title"></header>
    <layout-content>
      <div class="main-content">
        <div class="info">
          <header>
            <p>¥{{ pay.data.money }}</p>
            <span>{{ pay.data.money * proportion }}钻石</span>
          </header>
          <div class="user-info">
            <div class="text">
              <span>充值账号</span>
              <div>
                <img :src="userinfo.avatar" alt="" />
                {{ userinfo.user_nicename }}
              </div>
            </div>
            <div class="text">
              <span>充值账号ID</span>
              <div>{{ userinfo.id }}</div>
            </div>
          </div>
        </div>
        <div class="pay-type">
          <label for="pay">
            <div class="type">
              <div>
                <img src="../assets/images/pay.png" alt="" />
                <span>支付宝</span>
              </div>
              <input
                type="radio"
                name="pay-type"
                id="pay"
                value="pay"
                v-model="payType"
              />
            </div>
          </label>
          <label for="wechat">
            <div class="type">
              <div>
                <img src="../assets/images/wechat.png" alt="" />
                <span>微信支付</span>
              </div>
              <input
                type="radio"
                name="pay-type"
                id="wechat"
                value="wechat"
                v-model="payType"
              />
            </div>
          </label>
        </div>
      </div>
      <div class="pay" @click="onPay">确认支付</div>
      <div class="kefu">
        <a :href="kefu_url">联系客服</a>
      </div>
    </layout-content>
  </layout-wrapper>
</template>

<script setup>
import layoutWrapper from '@/components/layoutWrapper.vue';
import layoutContent from '@/components/layoutContent.vue';
import utilScreen from '@/components/utilScreen.vue';
import { ref, reactive, onMounted } from 'vue';
import { Notify } from 'vant';
import * as api from '@/api/index';
const VanNotify = Notify.Component;
const payList = reactive({
  data: [],
});

const userId = ref('');
const customMoney = ref('');
const active = ref(0);
const isUser = ref(false);
const userinfo = reactive({
  id: '',
  avatar: '',
  user_nicename: '',
});
const isFocus = ref(false);
const isTab = ref(true);
const payType = ref('pay');
const proportion = ref(0);
const kefu_url = ref('');
const u = navigator.userAgent;
const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

onMounted(async () => {
  const res = await api.getPayList();
  // console.log(res.data.data);
  payList.data = res.data.data.info;
  // 充值比例
  proportion.value = res.data.data.proportion;
  // 客服链接
  kefu_url.value = res.data.data.service_url;
  // payList.value = res.data.data.info;
});

// 搜索用户
const onSearchUser = async () => {
  if (userId.value.length > 4) {
    const params = new FormData();
    params.append('key', userId.value);
    const res = await api.getSelectUser(params);
    if (Object.keys(res.data.data.info).length !== 0) {
      // console.log(res.data.data.info);
      isUser.value = true;
      userinfo.avatar = res.data.data.info.avatar;
      userinfo.id = res.data.data.info.id;
      userinfo.user_nicename = res.data.data.info.user_nicename;
    } else {
      isUser.value = false;
      Notify({ type: 'danger', message: '未查询到此用户' });
    }
  }
};

// 选择的充值金额和用户id
const pay = reactive({
  userId: '',
  data: {},
});

// 选择充值金额
const onSelectMoney = (index) => {
  active.value = index;
};

// 自定义充值获取焦点
const onInputFocus = () => {
  isFocus.value = true;
  active.value = '';
};

// 立即充值
const onOwnPay = () => {
  if (userId.value === '') {
    Notify({ type: 'warning', message: '请输入您要充值的ID' });
    return;
  }
  if (userinfo.id === '') {
    Notify({ type: 'warning', message: '请输入您的id获取用户信息' });
    return;
  }
  if (active.value !== '') {
    // 列表金额
    pay.data = payList.data[active.value];
  } else {
    // 自定义金额
    pay.data = {
      coin: 1,
      types: '1',
      money: customMoney.value,
    };
  }
  pay.userId = userId.value;

  console.log(pay);
  isTab.value = false;
};
// 确认支付
const onPay = async () => {
  // console.log(pay);
  // console.log(userinfo);
  // isTab.value = true;
  const { userId, data } = pay;
  // 微信
  if (payType.value === 'wechat') {
    if (data.id) {
      const params = new FormData();
      params.append('uid', userId);
      params.append('changeid', data.id);
      params.append('coin', data.coin);
      params.append('money', data.money);
      const res = await api.getWxPay(params);
      window.location.href = res.data.data.info[0];
    } else {
      const params = new FormData();
      params.append('uid', userId);
      params.append('changeid', 0);
      params.append('coin', 0);
      params.append('types', '1');
      params.append('money', data.money);
      const res = await api.getWxPay(params);
      window.location.href = res.data.data.info[0];
    }
  }
  // 支付宝
  if (payType.value === 'pay') {
    if (data.id) {
      console.log('支付宝');
      const params = new FormData();
      params.append('uid', userId);
      params.append('changeid', data.id);
      params.append('coin', data.coin);
      params.append('money', data.money);
      const res = await api.getPay(params);
      window.location.href = res.data.data.info[0];
    } else {
      console.log('自定义');
      const params = new FormData();
      params.append('uid', userId);
      params.append('changeid', 0);
      params.append('coin', 0);
      params.append('types', '1');
      params.append('money', data.money);
      const res = await api.getPay(params);
      window.location.href = res.data.data.info[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title {
  height: 2.95rem;
  display: flex;
  align-items: center;
  padding-left: 27px;
  box-sizing: border-box;
  span {
    color: #000;
    font-weight: bold;
  }
}
.main-content {
  flex: 1;
  .input-pay {
    width: 100%;
    height: 2rem;
    outline: none;
    border: 0;
    padding: 12px 0 12px 15px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 5px 5px 5px 5px;
    font-size: 12px;
    &::placeholder {
      color: #656565;
      font-size: 12px;
    }
  }
  .userinfo {
    height: 4rem;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 14px;
    margin-bottom: 22px;
    padding: 15px 0 14px 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-size: contain;
    }
    .userinfo-info {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 16px;
      }
      p {
        font-size: 12px;
        color: #757575;
      }
    }
  }
  .tips {
    color: #ff7373;
    font-size: 12px;
    padding: 4px 0 0 15px;
  }
  .money-list {
    margin-top: 0.9px;
    // height: 8.25rem;
    background-color: #fff;
    border-radius: 5px;
    // padding: 0.3rem;
    box-sizing: border-box;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      height: 100%;
      padding-bottom: 0.6rem;
      &::after {
        content: '';
        width: 29%;
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 5.3rem;
        height: 3rem;
        margin-top: 0.6rem;
        background-color: #f8f8f8;
        border-radius: 5px;

        &.active {
          border: 1px solid #d45e7e;
          background-color: rgba(252, 233, 237, 0.7);
        }
        .top {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 18px;
            height: 14.5px;
            background-size: contain;
          }
          .coin {
            color: #000;
            font-weight: bold;
            font-size: 12px;
          }
        }
        span.money {
          font-size: 12px;
          color: #929292;
          padding: 3px;
          box-sizing: border-box;
        }
      }
      .custom {
        // width: 5rem;
        // height: 3rem;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        border: 0;
        outline: none;
        text-align: center;
        border-radius: 5px;
        font-size: 12px;
        // margin-top: 0.4rem;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 1.3em;
        font-weight: bold;
      }
      span {
        font-size: 14px;
        color: #535353;
      }
    }
    .user-info {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      .text {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #838383;
        span {
          font-size: 14px;
          color: #838383;
        }
        div {
          font-size: 14px;
          color: #838383;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 16px;
            height: 16px;
            background-size: contain;
            border-radius: 50%;
            margin-right: 6px;
          }
        }
      }
    }
  }
  .pay-type {
    margin-top: 19px;
    height: 6.25rem;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 0 21px 0 12px;
    box-sizing: border-box;
    label {
      flex: 1;
    }
    .type {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      input {
        width: 10%;
      }
      div {
        display: flex;
        flex: 1;
        align-items: center;
        img {
          width: 1.1rem;
          height: 1.1rem;
          background-size: contain;
        }
        span {
          margin-left: 10px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
.pay {
  background-color: #fc3e5a;
  border-radius: 3px;
  // height: 2.1rem;
  height: 40.5px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kefu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  a {
    text-decoration: none;
    font-size: 0.7rem;
    color: #000;
  }
}
</style>
