<template>
  <div class="container">
    <span class="info">将该网站添加到主屏幕，方便下次充值</span>
    <div class="util-btn" @click="onToPath('/util-about')">
      <span>点我添加</span>
    </div>
  </div>
</template>


<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

// 跳转到
const onToPath = (path) => {
  router.push(path);
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 1rem;
  width: 100%;
  height: 2rem;
  background-color: #dae5f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span.info {
    color: #777777;
    font-size: 13px;
    margin-left: 17px;
  }

  .util-btn {
    background: #6791eb;
    border: 1px solid #486ec0;
    border-radius: 7px;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin-right: 13px;
    span {
      color: #fff;
    }
  }
}
</style>