import request from "../utils/http";

// 获取充值列表
export const getPayList = (params) =>
  request.post("public//?service=Charge.getChargeRules", params);
// 微信充值
export const getWxPay = (params) =>
  request.post("public//?service=Charge.GetWxOrderH", params);
// 支付宝充值
export const getPay = (params) =>
  request.post("public//?service=Charge.GetAliOrderH", params);
// 查询用户
export const getSelectUser = (params) =>
  request.post("public//?service=Charge.search", params);
